/*
    Cookie
*/
export default class Cookie {
    static init() {
        return new Cookie();
    }

    constructor() {
        this.cookieCloseButton = document.getElementById('close-cookie');

        this.hideCookie(this.cookieCloseButton);
    }

    hideCookie(closeButton) {
        const that = this;
        closeButton.addEventListener('click', function() {
            that.toggleCookieNotification(this);
            if (that.getCookie('cookie') == '') {
                that.setCookie('cookie-accept', 'cookie', 4);
            }
        });
    }

    toggleCookieNotification(closeButton) {
        document.getElementById('cookie-notice').classList.toggle('hidden');
    }

    setCookie(name, value, days) {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    }

    getCookie(name) {
        const cookieName = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }
}
