/**
 *  Import includes
 */
import CookieNotice from './components/cookie-notice';

(function() {
    /**
     *  Example of CookieNotice implementaion
     */
    if (document.getElementById('cookie-notice')) {
        CookieNotice.init();
    }

    /**
     *  Toggle for mobile menu
     */
    document.getElementById('menu-toggle').onclick = function() {
        document.getElementById('menu-primary').classList.toggle('hidden');
    };

    /**
     *  Toggle for jumbotron explore
     */
    const jumbotronExploreButton = document.getElementById('jumbotron-explore-button');

    if (jumbotronExploreButton != null) {
        jumbotronExploreButton.onclick = function() {
            document.getElementById('jumbotron-explore-content').classList.toggle('active');
        };
    }

    /**
     *  Animated fixed menu size
     */
    const mq = window.matchMedia('(min-width: 1024px)');
    const offsetTop = 64;

    window.onscroll = function() {
        scrollFunction();
    };

    // Resets menu if resized from desktop
    window.onresize = function() {
        resizeFunction();
    };

    /**
     * Secures the navbar from resizing if window is resized
     */
    function resizeFunction() {
        if (mq.matches) return;

        document.getElementById('navbar-container').style.transform = 'translateY(0)';
        document.getElementById('navbar-logo').style.transform = 'scale(1)';
    }

    /**
     * Animates navbar when scrolled
     */
    function scrollFunction() {
        if (!mq.matches) return;

        if (
            document.body.scrollTop > offsetTop || document.documentElement.scrollTop > offsetTop
        ) {
            document.getElementById('navbar-container').style.transform = 'translateY(-4rem)';
            document.getElementById('navbar-logo').style.transform = 'scale(.5)';
        } else {
            document.getElementById('navbar-logo').style.transform = 'scale(1)';
            document.getElementById('navbar-container').style.transform = 'translateY(0)';
        }
    }
})();
